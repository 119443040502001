exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-alcohol-js": () => import("./../../../src/templates/alcohol.js" /* webpackChunkName: "component---src-templates-alcohol-js" */),
  "component---src-templates-beverages-js": () => import("./../../../src/templates/beverages.js" /* webpackChunkName: "component---src-templates-beverages-js" */),
  "component---src-templates-breakfast-js": () => import("./../../../src/templates/breakfast.js" /* webpackChunkName: "component---src-templates-breakfast-js" */),
  "component---src-templates-burgers-js": () => import("./../../../src/templates/burgers.js" /* webpackChunkName: "component---src-templates-burgers-js" */),
  "component---src-templates-dinner-js": () => import("./../../../src/templates/dinner.js" /* webpackChunkName: "component---src-templates-dinner-js" */),
  "component---src-templates-lightmeals-js": () => import("./../../../src/templates/lightmeals.js" /* webpackChunkName: "component---src-templates-lightmeals-js" */),
  "component---src-templates-menu-template-js": () => import("./../../../src/templates/menuTemplate.js" /* webpackChunkName: "component---src-templates-menu-template-js" */),
  "component---src-templates-pizza-js": () => import("./../../../src/templates/pizza.js" /* webpackChunkName: "component---src-templates-pizza-js" */),
  "component---src-templates-specials-js": () => import("./../../../src/templates/specials.js" /* webpackChunkName: "component---src-templates-specials-js" */)
}

